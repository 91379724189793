<template>
  <div error-page>
    <article class="contents">
      <div class="img-holder">
        <img src="/img/pages/character/error.png" alt="oops" />
      </div>
      <h2>Oops!</h2>
      <h4>
        The page you are looking for cannot be found.
      </h4>
      <p>요청하신 페이지를 찾을 수 없습니다.</p>
    </article>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage',
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[error-page] { .wh(100%, 100vh); .rel; .cover('@{bg}/error.jpg'); .bg-c; .min-h(600);
  .contents { .c(white); .tc; .abs; .lt(50%, 50%); .t-xyc; .pt(50);
    .img-holder { .wh(108); .mh-c; .rel; .mb(28);
      &:before {.cnt; .wh(120); .bgc(#483bda); .abs; .lt(50%, 50%); .t-xyc; .br(100); z-index: 0;}
      img {.block; .rel; .wh(100%); .mh-c; .abs; .lt(50%, 50%); .t-xyc; z-index: 1;}
    }
    h2 {.fs(36); .mb(8);}
    h4 {.fs(16, 20px); .mb(24);}
    p {.fs(16, 20px); .c(#938bf0);}
  }
  @media (@tl-up) {
    .contents { .pt(40);
      .img-holder { .wh(180); .mb(28);
        &:before {.wh(200);}
      }
      h2 {.fs(48); .mb(12);}
      h4 {.fs(20, 24px); .mb(28);}
      p {.fs(20, 24px);}
    }
  }
  @media (@ds-up) {
  }
}
</style>
